import React, { lazy, Suspense, StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-lightbox-component/build/css/index.css";
import "./index.scss";

const Header = lazy(() => import("./_header/_component"));
const AdminLogin = lazy(() => import("./login/_window"));
const Home = lazy(() => import("./home/_window"));
const Footer = lazy(() => import("./footer/_component"));
const AshteckInfo = lazy(() => import("./info/_window"));
const Canalizaciones = lazy(() => import("./canalizaciones/view"));
const Contacto = lazy(() => import("./contacto/_window"));
const Talleres = lazy(() => import("./talleres/view"));
const Eventos = lazy(() => import("./eventos/view"));
const Retiros = lazy(() => import("./retiros/view"));

document.addEventListener(
  "play",
  function (e) {
    const audios = document.getElementsByTagName("audio");
    for (var i = 0; i < audios.length; i++) {
      if (audios[i] !== e.target) audios[i].pause();
    }
  },
  true
);

ReactDOM.render(
  <Router>
    <StrictMode>
      <Suspense fallback={<div>Loading...</div>}>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={AdminLogin} />
          <Route exact path="/info" component={AshteckInfo} />
          <Route path="/eventos" component={Eventos} />
          <Route path="/retiros" component={Retiros} />
          <Route path="/canalizaciones" component={Canalizaciones} />
          <Route exact path="/contacto" component={Contacto} />
          <Route exact path="/talleres" component={Talleres} />
        </Switch>
        <Footer />
      </Suspense>
    </StrictMode>
  </Router>,
  document.getElementById("root")
);
